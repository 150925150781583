import styles from './ResetPassword.module.scss';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { SanaForm, NewPasswordFormGroup, FormGroup, PasswordField } from 'components/objects/forms';
import { resetPassword } from 'behavior/pages/resetPassword';
import { formatText } from 'utils/formatting';
import { connect } from 'react-redux';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { FormName } from 'behavior/pages';

const ResetPasswordFormBlock = ({
  id,
  token,
  minPasswordLength,
  resetPassword,
  name,
  email,
  isDesignerMode,
  className = null,
}) => {
  const [
    newPasswordTitle,
    minPasswordPlaceholderTemplate,
    repeatPasswordTitle,
    comparisonErrorMessage,
  ] = useSimpleTexts([
    'ChangePassword_NewPassword',
    'CreateAccount_MinPasswordLength',
    'ChangePassword_RetypePassword',
    'ChangePassword_CompareFailed',
  ]).texts;

  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minPasswordLength]);
  const onSubmit = useHandlerLockerInPreview(data => resetPassword(token, data.password));

  return (
    <SanaForm
      className={className}
      name={`${FormName.ResetPassword}_${id}`}
      onSubmit={isDesignerMode ? undefined : onSubmit}
    >
      <NewPasswordFormGroup
        fieldName="password"
        fieldTitle={newPasswordTitle}
        placeholder={newPasswordPlaceholder}
        userData={[name, email]}
        disabled={isDesignerMode}
      />
      <FormGroup
        fieldName="repeat"
        fieldTitle={repeatPasswordTitle}
        fieldComponent={PasswordField}
        autoComplete="off"
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
          equalsTo: { to: 'password', message: comparisonErrorMessage },
        }}
        disabled={isDesignerMode}
      />
      <SanaForm.SubmitRow
        textKey="Save"
        className={`${styles.noBottomMargin} ${isDesignerMode ? styles.vdMode : ''}`}
        disabled={isDesignerMode}
      />
    </SanaForm>
  );
};

ResetPasswordFormBlock.propTypes = {
  id: PropTypes.string.isRequired,
  token: PropTypes.string,
  minPasswordLength: PropTypes.number,
  resetPassword: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  isDesignerMode: PropTypes.bool,
};

export default connect(
  ({ page, settings }) => ({
    token: page.token,
    minPasswordLength: settings.profile.minPasswordLength,
    name: page.userData && page.userData.name,
    email: page.userData && page.userData.email,
  }),
  { resetPassword },
)(ResetPasswordFormBlock);
