exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContactUs_vd-captcha{margin-bottom:1.75rem}.ContactUs_no-bottom-margin{margin-bottom:0}.ContactUs_vd-mode button[disabled]{background-color:var(--theme-button_BackgroundColor,#1f7bc9);box-shadow:inset 0 var(--btn-boxShadowSize) 0 var(--theme-button_BottomBorderColor,#1962a1);color:var(--theme-button_Color,#fff)}.ContactUs_vd-mode button[disabled]:hover{box-shadow:inset 0 var(--btn-boxShadowSize) 0 var(--theme-button_Disabled_BottomBorderColor,#979797)}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.ContactUs_vd-captcha{margin-left:36%;text-align:left}}", ""]);

// exports
exports.locals = {
	"vd-captcha": "ContactUs_vd-captcha",
	"vdCaptcha": "ContactUs_vd-captcha",
	"no-bottom-margin": "ContactUs_no-bottom-margin",
	"noBottomMargin": "ContactUs_no-bottom-margin",
	"vd-mode": "ContactUs_vd-mode",
	"vdMode": "ContactUs_vd-mode"
};