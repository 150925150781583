import styles from './ContactUs.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SanaForm,
  EntityTemplateFields,
  useCaptchaOnSubmit,
  CaptchaFormGroup,
  extractEscapedTemplateFieldsAndFiles,
} from 'components/objects/forms';
import { CustomerType } from 'behavior/user/constants';
import { sendContactUsForm } from 'behavior/pages/contactUs';
import { RichText } from 'components/sanaText';
import { FormName } from 'behavior/pages';
import { DangerAlert } from 'components/primitives/alerts';
import useContactUsFields from './useContactUsFields';
import { DesignerModeCaptcha } from 'components/primitives/captcha';

const ContactUsFormBlock = ({
  id,
  isAuthenticated,
  email,
  name,
  customerName,
  phone,
  customerType,
  successStatus,
  updatedById,
  sendContactUsForm,
  isDesignerMode,
}) => {
  const formName = `${FormName.ContactUs}_${id}`;
  const templateFields = useContactUsFields(isDesignerMode);
  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    const { files, ...input } = createInputData(formData, templateFields);
    sendContactUsForm(input, files, formName);
  }, FormName.ContactUs, [formName, templateFields]);

  let initialValues = {};
  if (isAuthenticated) {
    const isB2c = CustomerType.B2C === customerType;
    initialValues = {
      ContactUs: {
        CompanyName: isB2c ? '' : customerName,
        Name: name,
        Email: email,
        PhoneNo: phone,
      },
    };
  }

  return (
    <SanaForm
      name={formName}
      onSubmit={isDesignerMode ? undefined : onSubmit}
      initialValues={initialValues}
      shouldReset={formName === updatedById}
    >
      {successStatus === false && (
        <DangerAlert role="alert" scrollOnAppear>
          <RichText textKey="ContactForm_MessageSendUnsuccessfully" />
        </DangerAlert>
      )}
      {templateFields && <EntityTemplateFields applyNamesEscaping fields={templateFields} disableAll={isDesignerMode} />}
      {isDesignerMode
        ? <DesignerModeCaptcha className={styles.vdCaptcha} />
        : <CaptchaFormGroup ref={captchaRef} captchaId={formName} />
      }
      <SanaForm.SubmitRow
        textKey="ContactSubmit"
        className={`${styles.noBottomMargin} ${isDesignerMode ? styles.vdMode : ''}`}
        disabled={isDesignerMode}
      />
    </SanaForm>
  );
};

ContactUsFormBlock.propTypes = {
  id: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  email: PropTypes.string,
  name: PropTypes.string,
  customerName: PropTypes.string,
  phone: PropTypes.string,
  customerType: PropTypes.string,
  sendContactUsForm: PropTypes.func.isRequired,
  successStatus: PropTypes.bool,
  updatedById: PropTypes.string,
  isDesignerMode: PropTypes.bool,
};

export default connect(mapStateToProps, { sendContactUsForm })(ContactUsFormBlock);

function mapStateToProps({ user, page }) {
  const { isAuthenticated } = user;
  const { successStatus, updatedById } = page;

  if (!isAuthenticated)
    return { isAuthenticated, successStatus, updatedById };

  const { name, email, customer, customerType } = user;
  const { name: customerName = '', phone = '' } = customer || {};

  return {
    isAuthenticated,
    email,
    name,
    customerName,
    phone,
    customerType,
    successStatus,
    updatedById,
  };
}

function createInputData(formData, templateFields) {
  const { captchaToken, ...data } = formData;
  return { captchaToken, ...extractEscapedTemplateFieldsAndFiles(data, templateFields) };
}
