exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Registration_form{padding-top:1.4em}.Registration_customer-data-section legend,.Registration_login-section-description{margin-bottom:1.4em}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.Registration_form-content{max-width:46.75em}}@media (min-width:env(--theme-breakpoints_Medium,960)px),print{.Registration_form-content{max-width:none}}", ""]);

// exports
exports.locals = {
	"form": "Registration_form",
	"customer-data-section": "Registration_customer-data-section",
	"customerDataSection": "Registration_customer-data-section",
	"login-section-description": "Registration_login-section-description",
	"loginSectionDescription": "Registration_login-section-description",
	"form-content": "Registration_form-content",
	"formContent": "Registration_form-content"
};